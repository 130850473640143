import storeOptions, { CatalogState } from './store';
import Vue from 'vue';
import Vuex from 'vuex';

import { shouldReload } from '../helpers/url';
import {
	updateMetaDescription,
	updateMetaKeywords,
	updateMetaRobots,
	updateTitle,
	updateTotalResultsDisplay,
	updateTrackingId
} from '../helpers/external-dom-updaters';

Vue.use(Vuex);

const store = new Vuex.Store(storeOptions);

/**
 * Watches for newly computed query string and sends off a search request
 */
store.watch(
	(_, getters) => getters.queryString,
	(newQueryString) => {
		if ((newQueryString ?? "") === window.location.search) return;

		// previously, we would push the URL without the query string into the history and
		// reload the page with window.location.reload. We need a full page load in the
		// case of an empty query for a multitude of reasons. As of the release of DXP,
		// reloading the page without a prod list query string may result in a totally
		// different kind of page being rendered without the prod list JS that handles
		// the popstate event. If we only did a reload, pressing back would then result
		// in a broken experience for those pages, where the URL would change, but the
		// browser wouldn't navigate back to a prod list.
		if (!newQueryString || shouldReload(newQueryString)) {
			store.commit('isLoadingSearchResult', true);
			const newUrl = new URL(window.location.href);
			newUrl.search = '';
			return window.location.href = newUrl.toString();
		}

		const url = window.location.origin + window.location.pathname + newQueryString;

		const historyState = {
			scroll: window.scrollY,
		};

		window.history.pushState(historyState, '', url);

		store.dispatch('searchProducts', newQueryString);
	},
);

// ensure client side state matches query string when page returned from bfcache
window.addEventListener('pageshow', e => {
	if (e.persisted && store.getters.queryString !== window.location.search) {
		store.dispatch('searchProducts', window.location.search);
	}
});

/**
 * Listens for "Back" button press to send off a request for the previous URL
 */
window.addEventListener('popstate', e => {
	// restore previous scroll position
	if (e.state && e.state.scroll) {
		window.scrollTo({
			top: e.state.scroll,
		});
	}

	// if we're going back to a landing page, we need to make sure to reload the page
	// because searchProducts may leave the facet selection in a weird state
	if (!window.location.search) {
		// note that this call to reload is not subject to the issues related
		// to DXP mentioned elsewhere in this file
		window.location.reload();
	} else {
		store.dispatch('searchProducts', window.location.search);
	}
});

store.watch(
	(state: CatalogState) => state.page,
	() => {
		setTimeout(() => {
			const listWrapper = document.querySelector("#productListWrapper");
			if (listWrapper) {
				listWrapper.scrollIntoView(true);
			} else {
				window.scrollTo({
					top: 0,
				});
			}
		}, 0);
	}
);

/**
 * Loading message watcher
 *
 * When loadingMessage changes, this updates an area live region with its new value
 * to alert screenreaders
 */
store.watch(
	(state: CatalogState) => state.loadingMessage,
	loadingMessage => {
		let loadingMessageLiveRegion = document.getElementById('loading-message-live-region');
		if (!loadingMessageLiveRegion) {
			loadingMessageLiveRegion = document.createElement('div');

			loadingMessageLiveRegion.classList.add('hide-aria');
			loadingMessageLiveRegion.id = 'loading-message-live-region';

			loadingMessageLiveRegion.setAttribute('aria-live', "polite");

			document.body.appendChild(loadingMessageLiveRegion);
		}

		loadingMessageLiveRegion.textContent = loadingMessage;
	}
);

/**
 * Show/Hide Landing page content
 */
store.watch(
	(state: CatalogState, getters) => state.isLoadingSearchResult || getters.showProducts,
	hideLandingPage => {
		const appWrapper = document.querySelector('#productListWrapper');

		if (!appWrapper) return;

		if (hideLandingPage) {
			appWrapper.classList.remove('is-landing-page');
		} else {
			appWrapper.classList.add('is-landing-page');
		}
	}
);

/**
 * Show/Hide header
 */
store.watch(
	(_state, getters) => getters.showProducts,
	showHeader => {
		const appHeader = document.querySelector('.product-list-head');

		if (!appHeader) return;

		if (showHeader) {
			appHeader.classList.remove('is-landing-page');
		} else {
			appHeader.classList.add('is-landing-page');
		}
	}
);

/**
 * Update DOM outside of Vue apps
 */
store.watch(
	(state: CatalogState) => state.searchResult,
	searchResult => {
		if (!searchResult) return;

		if (searchResult.includeProducts && searchResult.relaxedSearch) {
			updateTitle("We didn’t find an exact match, but here are items that matched one or more words.");
		} else if (searchResult.original) {
			updateTitle(`Showing Results for “${searchResult.keyword}”`);
		} else {
			updateTitle(searchResult.headerTitle);
		}

		updateTotalResultsDisplay(searchResult.totalProducts);

		if (searchResult.isFiltered) {
			updateMetaDescription(searchResult.metaDescription);
			updateMetaKeywords(searchResult.metaKeywords);
		}
		updateMetaRobots(searchResult.metaRobots);
		updateTrackingId(searchResult.trackingId);
	}
);

export default store;